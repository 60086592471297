import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  // 指定的存储是本地存储
  storage: window.localStorage
})
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {}, // token
    user_mes: {}, // 用户数据
    integratePortsData: {}, // 综合数据
    magnifications: {}, // 倍率
  },
  getters: {},
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    integratePortsData (state, data) {
      state.integratePortsData = data
    },
    magnifications (state, data) {
      state.magnifications = data
    },
    user_xx (state, xx) {
      state.user_mes = xx
      if (state.user_mes.currentmemLevel == null) {
        state.user_mes.currentmemLevel = { title: 'VIP1' }
      }
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
})
