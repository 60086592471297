import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import ElementUI from 'element-ui'
import './styles/reset.less'// 引入全局样式
import './styles/common.less'// 解决兼容性布局
import './styles/theme.css'
import './styles/animate.css'
import './styles/compatibility.less'// 解决兼容性布局
import { parseTime } from "@/utils/ruoyi";
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/icons/index'
import '@vant/touch-emulator'
// vant组件库适配pc端
import { CountDown } from 'vant'
require('es6-promise').polyfill()
Es6Promise.polyfill()
// 弹出框的时候滚动条隐藏和出现导致页面抖动问题
ElementUI.Dialog.props.lockScroll.default = false;
import _ from 'lodash'
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
Vue.prototype._ = _  //全局导入的挂载方式
Vue.use(CountDown)
Vue.use(ElementUI)
import scroll from 'vue-seamless-scroll'//vue文字无缝滚动插件vue-seamless-scroll
Vue.use(scroll)
import jquery from 'jquery'
Vue.prototype.$ = jquery
Vue.prototype.parseTime = parseTime
Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
