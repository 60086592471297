import store from '@/store'
import axios from 'axios'
import { tansParams } from '@/utils/ruoyi'
const instance = axios.create({
  baseURL: '/api',
  timeout: 5000,
  withCredentials: true // 允许携带cookie
})
// 发送请求前处理request的数据
axios.defaults.transformRequest = [function (data) {
  let newData = ''
  for (let k in data) {
    newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
  }
  return newData
}]
export default instance
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const tokenStr = localStorage.getItem('token')
    const website = localStorage.getItem('website')
    const protocol = document.location.protocol
    if (tokenStr) {
      config.headers = {
        AuthorizationMEM: 'Bearer ' + tokenStr
      }
    }
    if (config.url === '/captchaImage') {
      config.headers = {}
    }
    if (website === null) {
      console.log('website', 'https.js');
    } else {
      // console.log(1231231);
      if (config.url === '/version/collect') {
        config.url = protocol + '//' + website + '/api/version/collect'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/system/user/longUser/pkScoreBalance') {
        config.url = protocol + '//' + website + '/api/system/user/longUser/pkScoreBalance'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/cashBenefits') {
        config.url = protocol + '//' + website + '/api/cashBenefits'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/exchange/response') {
        config.url = protocol + '//' + website + '/api/exchange/response'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/system/user/setting/exTip') {
        config.url = protocol + '//' + website + '/api/system/user/setting/exTip'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/system/user/setting/exchange') {
        config.url = protocol + '//' + website + '/api/system/user/setting/exchange'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/order/daily/gift/getDailyGift') {
        config.url = protocol + '//' + website + '/api/order/daily/gift/getDailyGift'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/order/daily/gift/response') {
        config.url = protocol + '//' + website + '/api/order/daily/gift/response'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/wechat/image/pkCoin') {
        config.url = protocol + '//' + website + '/api/wechat/image/pkCoin'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/wechat/image/score') {
        config.url = protocol + '//' + website + '/api/wechat/image/score'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
      if (config.url === '/wechat/member/conversion') {
        config.url = protocol + '//' + website + '/api/wechat/member/conversion'
        config.headers = {
          'Access-Control-Allow-Origin': '*'
        }
        config.headers = {
          AuthorizationMEM: 'Bearer ' + tokenStr
        }
      }
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code === 401) {
      // console.log(response.data);
      store.commit('setUser', {}) // 把本地的token清空
      store.commit('user_xx', {}) // 把本地的token清空
      window.localStorage.removeItem('token')// 把本地的token清空
      window.open('/', '_self')
      // console.log(response);
    }
    if (response.data.code === 402) {
      // console.log(response.data);
      store.commit('setUser', {}) // 把本地的token清空
      store.commit('user_xx', {}) // 把本地的token清空
      window.localStorage.removeItem('token')// 把本地的token清空
      window.open('/file-api/404.html', '_self')
      // console.log(response);
    }
    return response
  },
  function (error) {
  }
)
